import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { oo } from "../element/theme";
import UrlValidationFeedback from "./UrlValidationFeedback";
import { validateUrl } from "../element/form/validate-url";
import routes from "../navigation/routes";
import Input from "@material-ui/core/Input";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import IconButton from "@material-ui/core/IconButton";
import { lessOrEqualThan } from "../element/mediaQuery";
import PreviewCtaButton from "./PreviewCtaButton";
import IFramePreview from "./IFramePreview";


const TemplatePreview = ({ maxWidth, url, template }) => {

  const handleFocus = (event) => event.target.select();

  /* --- Handle case: No URL supplied, give feedback --- */
  let displayFeedback;
  let validatedUrl;
  if (url == null || (url.length === 0)) {
    /* Handle empty URL */
    validatedUrl = { isValidUrl: false, url: null };
    displayFeedback = <UrlValidationFeedback feedback={0} />;
  } else {
    /* Validate URL */
    validatedUrl = validateUrl(url);
    if (!validatedUrl.isValidUrl) displayFeedback = <UrlValidationFeedback feedback={1} />;
  }

  /* Set UI depending on device size */
  let height = 60;
  let mobileSpeaker;
  let mobileBottom;
  let isMobileDevice = false;
  if (maxWidth === oo.mobileMinWidth) {
    isMobileDevice = true;
    height = 45;
    mobileSpeaker = (
      <BarWrapper>
        <MobileBar barWidth="3.75rem" barHeight="0.375rem" barMargin="0.3rem" />
      </BarWrapper>
    );
    mobileBottom = (
      <MobileBottom maxWidth={maxWidth}>
        <MobileCircle />
      </MobileBottom>
    );
  }
  return (
    <>
      <div>
        {/* <div> fixes unexplainable wrapping of <UrlValidationFeedback> around <DesktopUi> */}
        {displayFeedback}
      </div>
      <DesktopUi maxWidth={maxWidth} height={height}>
        <div>
          <Circle style={{ marginLeft: "1rem" }} />
          <Circle />
          <Circle />
        </div>
        {mobileSpeaker}
        <AddressBarForm noValidate autoComplete="off"
                        action={routes.preview.to}
                        method="get"
                        isMobileDevice={isMobileDevice}>
          <AddressBar isMobileDevice={isMobileDevice}>
            <Input placeholder="https://store.steampowered.com/app/..."
                   defaultValue={url}
                   id="url"
                   name="url"
                   disableUnderline
                   fullWidth
                   onFocus={handleFocus}
                   startAdornment={
                     <InputAdornment position="start">
                       <SearchIcon color="disabled" />
                     </InputAdornment>
                   }
                   endAdornment={
                     <IconButtonSt type="submit" value="submit"
                                   size="small" $isMobileDevice={isMobileDevice}>
                       <ArrowForwardIcon color="disabled" />
                     </IconButtonSt>
                   }
            />
          </AddressBar>
        </AddressBarForm>
        <IFramePreview url={validatedUrl.url} />
        {mobileBottom}
      </DesktopUi>
      <PreviewCtaButton />
    </>
  );
};
TemplatePreview.propTypes = {
  maxWidth: PropTypes.number.isRequired,
  url: PropTypes.string,
  template: PropTypes.string,
};
export default TemplatePreview;


const DesktopUi = styled.div`
  margin-bottom: ${oo.verticalSpacing * 3}rem;
  height: ${props => props.height}rem;
  width: 100%;
  max-width: ${props => props.maxWidth}rem;
  background: ${oo.paperBackgroundLight};
  box-shadow: 0 0 100px rgba(0,0,0,.07);
  border-radius: 0.6rem;
  border: 1px solid ${oo.grey7};
  display: flex;
  flex-flow: column nowrap;
`;
const Circle = styled.div`
  display: inline-block;
  margin: 11px 5px;
  width: 6px;
  height: 6px;
  border: 1px solid #b4b4b4;
  border-radius: 3px;
`;
const MobileBottom = styled.div`
  height: 5.5rem;
  width: 100%;
  max-width: ${props => props.maxWidth}rem;
  background: inherit;
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;
const MobileCircle = styled.div`
  border: 1px solid ${oo.grey7};
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  margin-bottom: 1rem;
`;
const BarWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const MobileBar = styled.div`
  margin: 0 ${props => props.barMargin} 1rem ${props => props.barMargin};
  border: 1px solid ${oo.grey7};
  border-radius: 3px;
  height: ${props => props.barHeight};
  width: ${props => props.barWidth};
`;
const AddressBarForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4rem 1rem 4rem;
  ${lessOrEqualThan.sm`margin: 0 1rem 1rem 1rem;`};
  ${({ isMobileDevice }) => isMobileDevice && `margin: 0;`}
`;
const AddressBar = styled.div`
  padding: 0 0.5rem;
  ${({ isMobileDevice }) => isMobileDevice && `padding: 0 0.25rem;`};
  border: 1px solid ${oo.grey7};
  border-radius: 3px;
  height: 2rem;
  width: 100%;
`;
const IconButtonSt = styled(IconButton)`
  margin-left: 1rem;
  ${({ $isMobileDevice }) => $isMobileDevice && `margin-left: 0;`}
`;