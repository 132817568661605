import React, { useContext } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Button } from "@material-ui/core";
import { AuthContext } from "../auth/AuthContextProvider";
import routes from "../navigation/routes";

const PreviewCtaButton = () => {
  let to;
  if (useContext(AuthContext).isLoggedIn()) to = routes.user.sites.create.to;
  else to = routes.login.to;

  return (
    <Button component={GatsbyLink} to={to}
            variant="contained" color="primary" size="large"
    >
      Create Website
    </Button>
  );
};
export default PreviewCtaButton;