const startsWith = "https://store.steampowered.com/app/";

/**
 * Validates URL
 * @return false if not a valid Steam Store URL. Otherwise @return appid.
 */
export const validateUrl = (url) => {
  if (typeof url !== "string") return { isValidUrl: false, url: null };
  const trimmedUrl = url.trim();
  if (!trimmedUrl.startsWith(startsWith)) return { isValidUrl: false, url: null };
  return { isValidUrl: true, url: trimmedUrl };
};