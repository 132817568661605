import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { oo } from "../element/theme";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import PhoneAndroidOutlinedIcon from "@material-ui/icons/PhoneAndroidOutlined";
import { Divider } from "@material-ui/core";
import Logo from "../element/graphical/Logo";
import PreviewCtaButton from "./PreviewCtaButton";
import { greaterOrEqualThan, lessOrEqualThan } from "../element/mediaQuery";
import Typography from "@material-ui/core/Typography";


const PreviewHeader = ({ resizeToDevice }) => {
  const buttonClick = (device) => resizeToDevice(device);

  return (
    <>
      <PreviewHeaderContainer>
        <PreviewOptionsWrapper>
          <Logo color />

          <DeviceOptionsContainer>
            <IconButton aria-label="delete" style={{ marginLeft: "2rem", marginRight: "1.2rem" }}
                        onClick={() => buttonClick("desktop")}>
              <DesktopWindowsIcon style={iconStyle} />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton aria-label="delete"
                        onClick={() => buttonClick("mobile")}>
              <PhoneAndroidOutlinedIcon style={iconStyle} />
            </IconButton>
          </DeviceOptionsContainer>

          <GrowDiv />
          <PreviewCtaButton />

        </PreviewOptionsWrapper>
      </PreviewHeaderContainer>
      <Typography component="p" variant="h5" align="center" style={{ marginTop: "3rem" }}>This is a
        preview. More is coming soon. 🚀</Typography>
    </>
  );
};
PreviewHeader.propTypes = {
  resizeToDevice: PropTypes.func.isRequired,
};
export default PreviewHeader;

const PreviewHeaderContainer = styled.header`
  border-bottom: 1px solid ${oo.grey7};
  background-color: ${oo.paperBackgroundLight};
`;
const PreviewOptionsWrapper = styled.div`
  max-width: ${oo.contentWidth}rem;
  margin: 0 auto;
  padding: 1rem 1rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  ${lessOrEqualThan.sm`justify-content: center;`};
`;
const DeviceOptionsContainer = styled.div`
  display: flex;
  ${lessOrEqualThan.sm`
    padding: 1rem 0;
    justify-content: center;
    align-items: center;
  `};
`;
const iconStyle = { height: "100%", width: "100%" };
const IconButton = styled.button`
  all: unset;
  color: ${oo.grey3};
  width: 2rem;
  height: 2rem;
  margin: 0 1rem;
  cursor: pointer;
  :focus {
    outline: ${oo.oberionTealDark} auto 1px;
  }
  :hover {
    color: ${oo.oberionTealDark};
  }
`;
const GrowDiv = styled.div`
  ${greaterOrEqualThan.sm`flex-grow: 1;`};
`;