import React from "react";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { oo } from "../element/theme";
import routes from "../navigation/routes";
import PropTypes from "prop-types";
import { lessOrEqualThan } from "../element/mediaQuery";

const txt = [
  "What game do you need?",
  "The Steam URL could not be read.",
];
const tryTxt = "Copy & paste the URL directly from Steam like these examples: ";
const links = [
  ["Dead Cells", "https://store.steampowered.com/app/588650/Dead_Cells/"],
  ["Deep Rock Galactic", "https://store.steampowered.com/app/548430/Deep_Rock_Galactic/"],
  ["Slime Rancher", "https://store.steampowered.com/app/433340/Slime_Rancher/"],
  ["SUPERHOT", "https://store.steampowered.com/app/322500/SUPERHOT/"],
];
const path = routes.preview.to + "?url=";

const UrlValidationFeedback = ({ feedback }) => {
  return (
    <UrlValidationDiv>
      <Typography component="p" variant="h6" gutterBottom>
        <ErrorSpan>{txt[feedback]}</ErrorSpan>
      </Typography>
      <Typography variant="body2">
        {tryTxt}
      </Typography>

      <TableSt className="markdown">
        <tbody>
        {links.map((el, index) => (
          <tr key={index}>
            <td style={{ minWidth: "4.7rem" }}>
              <a href={path + el[1]}>
                {el[0]}
              </a>
            </td>
            <td>
              ►
            </td>
            <td>
              {el[1]}
            </td>
          </tr>
        ))}
        </tbody>
      </TableSt>
    </UrlValidationDiv>
  );
};
UrlValidationFeedback.defaultProps = {
  feedback: 0,
};
UrlValidationFeedback.propTypes = {
  feedback: PropTypes.number.isRequired,
};
export default UrlValidationFeedback;

const ErrorSpan = styled.span`
  color: ${oo.textErrorMain};
`;
const UrlValidationDiv = styled.div`
  background-color: ${oo.grey8};
  padding: 0.5rem 1rem;
  ${lessOrEqualThan.sm`padding: 0.5rem 0`};
  margin-bottom: ${oo.verticalSpacing}rem;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
`;
const TableSt = styled.table`
  width: 100%;
  overflow-wrap: break-word; 
  word-break: break-all;
`;