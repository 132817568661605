import React, { useState } from "react";
import PropTypes from "prop-types";
import SEO from "../components/element/SEO";
import routes from "../components/navigation/routes";
import LayoutMain from "../components/element/layout/LayoutMain";
import LayoutColumn from "../components/element/layout/LayoutColumn";
import TemplatePreview from "../components/preview/TemplatePreview";
import PreviewHeader from "../components/preview/PreviewHeader";
import ooTheme, { oo } from "../components/element/theme";
// import CopyrightModal from "../components/preview/CopyrightModal";
import { useWindowSize } from "web-api-hooks";

const seo = {
  title: "Create your website - Use your Steam page as a template - Oberion",
  description: "Oberion uses your screenshots, descriptions and gifs of your game on Steam to create a website for you within 5 minutes for free.",
  canonical: routes.baseUrl + routes.preview,
};

const Preview = ({ location }) => {

  /* --- Validate URL parameters --- */
  const urlParams = new URLSearchParams(location.search);
  const url = urlParams.get("url");
  const template = urlParams.get("template");

  /* Set UI depending on device size */
  let vw = 1120; // === oo.contentWidth in px
  const [windowWidth, windowHeight] = useWindowSize();
  if (windowWidth > 0) vw = windowWidth;
  let defaultWidth;
  if (vw < ooTheme.breakpoints.values.md) {
    defaultWidth = oo.mobileMinWidth;
  } else {
    defaultWidth = oo.contentWidth;
  }
  const [deviceSize, setDeviceSize] = useState(defaultWidth);

  const resizeToDevice = (device) => {
    if (device === "mobile") return setDeviceSize(oo.mobileMinWidth);
    else return setDeviceSize(oo.contentWidth);
  };

  return (
    <>
      <SEO {...seo} />
      <PreviewHeader resizeToDevice={resizeToDevice} />

      <LayoutMain>
        <LayoutColumn width="100%" paddingBottom={oo.verticalSpacing * 2}>

          <TemplatePreview maxWidth={deviceSize} url={url} template={template} />

        </LayoutColumn>
      </LayoutMain>

      {/*<CopyrightModal />*/}
    </>
  );
};
Preview.propTypes = {
  location: PropTypes.object.isRequired,
  url: PropTypes.string,
  template: PropTypes.string,
};
export default Preview;