import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const iframeStyle = {
  width: "100%",
  flexGrow: "1",
  border: "none",
};


const IFramePreview = ({ url, template }) => {
  const [iframeSrc, setIFrameSrc] = useState(process.env.GATSBY_OBERION_ORIGIN_PREVIEW);

  useEffect(() => {
    if (url === null) return;
    setIFrameSrc(process.env.GATSBY_OBERION_ORIGIN_PREVIEW + "/?url=" + url);
  }, [url]);


  return (
    <iframe src={iframeSrc} style={iframeStyle} />
  );
};
IFramePreview.propTypes = {
  url: PropTypes.string,
  template: PropTypes.string,
};
export default IFramePreview;